<template>
    <crud-index-component v-bind="$data">
        <template slot="header">
            {{ $t('crud.zones._plural') }}
        </template>
        <template slot="pre-card">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="form-group col-md-4 col-lg-3">
                            <form @submit.prevent="updateAllPrices(form_update_all_prices)">
                                <b-input-group :label="$t('crud.zones.form_update_all_prices.amount')" size="sm">
                                    <b-input-group-prepend>
                                        <b-input-group-text>€</b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input v-model="form_update_all_prices.amount" @keypress.native="onlyNumber" required></b-form-input>
                                    <b-input-group-append>
                                        <loading-button :loading="loading_form_update_all_prices" class="btn btn-primary" type="submit">{{ $t('crud.zones.form_update_all_prices.submit') }}</loading-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-muted">{{ $t('crud.zones.form_update_all_prices.description') }}</small>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </crud-index-component>
</template>

<script>
    import CrudIndexComponent from "../crud/Index";
    import {config} from '../crud/mixin_config';

    export default {
        name: 'zones-index-component',
        components: {CrudIndexComponent},
        data() {
            return {
                crud: config('zones'),
                form_update_all_prices: {
                    amount: undefined
                },
                loading_form_update_all_prices: false,
                fields: [{
                    title: this.$t('crud.id'),
                    name: 'id',
                    sortField: 'id'
                }, {
                    title: this.$t('crud.name'),
                    name: 'name',
                    sortField: 'name'
                }, {
                    title: this.$t('crud.zones.price_euro_pallet'),
                    name: 'price_euro_pallet',
                    sortField: 'price_euro_pallet',
                    formatter: this.$options.filters.toCurrency
                },{
                    title: this.$t('crud.zones.price_block_pallet'),
                    name: 'price_block_pallet',
                    sortField: 'price_block_pallet',
                    formatter: this.$options.filters.toCurrency
                },{
                    title: this.$t('crud.zones.price_morning_delivery'),
                    name: 'price_morning_delivery',
                    sortField: 'price_morning_delivery',
                    formatter: this.$options.filters.toCurrency
                },{
                    title: this.$t('crud.actions'),
                    name: 'slot-actions'
                }]
            };
        },
        methods: {
            /**
             * Update all prices for all pickup locations with the given value.
             * @return {Promise}
             */
            updateAllPrices(form) {
                let amount = this.$options.filters.toCurrency(form.amount);
                if(confirm(this.$t('crud.zones.form_update_all_prices.confirm', {amount: amount}))) {
                    this.loading_form_update_all_prices = true;
                    return this.$http.post('zones/update-all-prices', form)
                        .then(() => {
                            this.loading_form_update_all_prices = false;
                            this.$toastr.s(this.$t('crud.zones.form_update_all_prices.success', {amount: amount}));
                        }).catch(() => {
                            this.loading_form_update_all_prices = false;
                        });
                }
                return Promise.reject('Not confirmed');
            }
        }
    }
</script>
